import { ListItem } from "./type";

export const world: ListItem[] = [
  {
    label: 'Культура',
    link: '/culture',
  },
  {
    label: 'Политика',
    link: '/politic',
  },
  {
    label: 'Карта',
    link: '/map',
  },
  // {
  //   label: 'Отряды',
  //   link: '/war-bands',
  // },
  // {
  //   label: 'Город Солем',
  //   link: '/solem',
  // },
  // {
  //   label: 'Жители города',
  //   link: '/people',
  // },
]