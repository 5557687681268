import { ListItem } from "./type";

export const rules: ListItem[] = [
  {
    label: 'Основные правила',
    link: '/general',
  },
  {
    label: 'Боевые взаимодействия',
    link: '/battle',
  },
  {
    label: 'Игровые действия',
    link: '/actions',
  },
  {
    label: 'Ресурсы, предметы и карты',
    link: '/resources',
  },
  // {
  //   label: 'Архетипы',
  //   link: '/role',
  // },
  // {
  //   label: 'Апостолы и одержимые',
  //   link: '/monsters',
  // },
  {
    label: 'Психозы',
    link: '/psycho',
  },
  {
    label: 'Лагерь и постройки',
    link: '/location',
  },
  // {
  //   label: 'Финальная битва',
  //   link: '/final-battle',
  // },
]